// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

// let slider = document.querySelector('.section--projects__slider');

// // Création de la timeline GSAP
// const timeline = gsap.timeline({
//   scrollTrigger: {
//     trigger: '.section--projects',
//     start: "top top",
//     endTrigger: '.section--gallery',
//     end: "bottom top",
//     scrub: true,
//     pin: true,
//     invalidateOnRefresh: true
//   }
// });

// timeline.to(slider, {
//   xPercent: -100,
//   yPercent: -30, // Défilement diagonal
//   duration: 3,
//   ease: "none"
// });



//DEFILEMENT DIAGONAL DES PROJETS DANS L'ACUEIL
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Fonction pour vérifier si l'utilisateur est sur un appareil desktop
function isDesktop() {
  return window.matchMedia("(min-width: 1024px)").matches;
}

// Vérifier si l'utilisateur est sur un appareil desktop avant d'exécuter le code GSAP
if (isDesktop()) {
  let slider = document.querySelector('.section--projects__slider');

  // Création de la timeline GSAP
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: '.section--projects',
      start: "top top",
      endTrigger: '.section--gallery',
      end: "bottom top",
      scrub: true,
      pin: true,
      invalidateOnRefresh: true
    }
  });

  timeline.to(slider, {
    xPercent: -65, //débute et finit au milieu des li 
    yPercent: -33, // Défilement diagonal
    duration: 3,
    ease: "none"
  });
}




// Accomplishments — Js Marquee
document.addEventListener('DOMContentLoaded', function() {
  const marqueeContent = document.getElementById('marqueeContent');
  const containerWidth = marqueeContent.parentElement.offsetWidth;
  const contentWidth = marqueeContent.offsetWidth;
  
  let startPosition = 15;
  let currentPos = startPosition;
  let scrollSpeed = getScrollSpeed(); // Détermine la vitesse initiale en fonction de la largeur de l'écran
  let isPaused = false;

  function animateMarquee() {
      if (!isPaused) {
          currentPos -= scrollSpeed;
          if (currentPos <= -contentWidth) {
              currentPos = containerWidth;
          }
          marqueeContent.style.transform = `translateX(${currentPos}px)`;
      }
      requestAnimationFrame(animateMarquee);
  }

  animateMarquee();

  marqueeContent.addEventListener('mouseover', function() {
      isPaused = true;
  });

  marqueeContent.addEventListener('mouseout', function() {
      isPaused = false;
  });

  marqueeContent.addEventListener('mousedown', function() {
      isPaused = true;
  });

  marqueeContent.addEventListener('mouseup', function() {
      isPaused = false;
  });

  function getScrollSpeed() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
          return 1;
      } else if (screenWidth >= 768 && screenWidth < 1024 ) {
          return 1.6;
      } else {
          return 2;
      }
  }
});


///////Animation au scroll


//Section presentation
gsap.to(".section--presentation", {
  borderBottomLeftRadius: "25px",
  borderBottomRightRadius: "25px",
  scrollTrigger: {
    trigger: ".section--presentation__txt-container", // Déclencheur de l'animation
    start: "top 50%", // Point de déclenchement de l'animation
    end: "bottom center", // Point de fin de l'animation
    scrub: 1 // Smooth scrolling
  }
});

//Section video
gsap.to(".section--video__bg", {
  // borderRadius: "40px", // Commencez avec le border-radius à 0%
  scrollTrigger: {
    trigger: ".section--video", // Déclencheur de l'animation
    start: "top top", // Point de déclenchement de l'animation
    end: "bottom 10%", // Point de fin de l'animation
    scrub: 1 // Smooth scrolling
  }
});


// Animation du texte
gsap.from(".section--video .h1", {
  y: 50, // Décalage de 50px vers le bas
  duration: 1, // Durée de l'animation
  scrollTrigger: {
    trigger: ".section--video", // Déclencheur de l'animation
    start: "top 80%", // Point de déclenchement de l'animation
    end: "bottom 20%", // Point de fin de l'animation
    scrub: 1 // Smooth scrolling
  }
});







//Animation des img : apparition au scroll
gsap.set(".item-img", { y: 100 });

ScrollTrigger.batch(".item-img", {
  onEnter: (batch) =>
    gsap.to(batch, {
      duration: 1,
      autoAlpha: 1,
      y: 0,
      stagger: 0.1
    }),
  start: "top center"
});

//https://gsap.com/community/forums/topic/39397-add-image-reveal-effect-on-scroll/




// // Animation des images dans accueil gallery
// const images = gsap.utils.toArray('.section--gallery__img img');
// const gallerySection = document.querySelector('.section--gallery');
// const imageHeight = window.innerHeight; // Hauteur d'une seule image, à ajuster si nécessaire

// // Calcul de la hauteur totale de la section
// const galleryHeight = images.length * imageHeight + 400; // 200vh au total (100vh avant + 100vh après)

// // Définition de la hauteur de la section
// gallerySection.style.height = `${galleryHeight}px`;

// images.forEach((img, i) => {
//     gsap.fromTo(img, {
//         y: window.innerHeight
//     }, {
//         y: -2000, // Position de départ des images
//         scrollTrigger: {
//             trigger: gallerySection,
//             start: `top+=${i * imageHeight} bottom`,
//             end: `top+=${(i + 1) * imageHeight} top`,
//             scrub: true
//         }
//     });
// });

// ScrollTrigger.create({
//   trigger: gallerySection,
//   start: 'top top',
//   end: `+=${galleryHeight}px`,
//   pin: true,
//   pinSpacing: false
// });












//MODAL (POP UP)
// Get the modal
var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
var span = document.querySelector(".modal-content__close");

// Function to open the modal
function openModal() {
    modal.style.display = "block";
    showSlides(slideIndex); // Appeler votre fonction pour afficher les slides
}

// Function to close the modal
function closeModal() {
    modal.style.display = "none";
}

// When the user clicks on the button, open the modal
btn.onclick = function() {
    openModal();
};

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
    closeModal();
};

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
    if (event.target == modal) {
        closeModal();
    }
};

// When the user presses the 'Escape' key, close the modal
document.onkeydown = function(event) {
    if (event.key === "Escape") { // Vérifie si la touche pressée est 'Escape'
        closeModal();
    }
};





// Fonction pour gérer le diaporama
function initializeSlideshow() {
    var slideIndex = 1;
    
    function showSlides(n) {
        var slides = document.getElementsByClassName('modal-content__slideshow-slides');
        if (n > slides.length) { slideIndex = 1; }
        if (n < 1) { slideIndex = slides.length; }
        
        for (var i = 0; i < slides.length; i++) {
            slides[i].classList.remove('active');
        }
        
        slides[slideIndex - 1].classList.add('active');
    }

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    function handleKeydown(event) {
        if (event.key === 'ArrowLeft') {
            plusSlides(-1);
        } else if (event.key === 'ArrowRight') {
            plusSlides(1);
        }
    }

    // Initialiser le diaporama à la première diapositive lorsque le document est prêt
    document.addEventListener('DOMContentLoaded', function() {
        showSlides(slideIndex);
        document.addEventListener('keydown', handleKeydown);
    });

    // Nettoyer les écouteurs d'événements lorsque le diaporama est désactivé
    return function cleanup() {
        document.removeEventListener('keydown', handleKeydown);
    }
}

// Fonction pour vérifier la largeur de l'écran
function checkScreenWidth() {
    if (window.matchMedia('(min-width: 1024px)').matches) {
        var cleanup = initializeSlideshow();
        window.addEventListener('resize', function() {
            if (!window.matchMedia('(min-width: 1024px)').matches) {
                cleanup();
            }
        });
    }
}

// Initialiser la vérification de la largeur de l'écran
checkScreenWidth();
window.addEventListener('resize', checkScreenWidth);





//REMPLACER VIDEO BG PAR IMG SUR SAFARI
function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

window.onload = function() {
  if (isSafari()) {
      document.getElementById('background-video').style.display = 'none';
      document.getElementById('background-image').style.display = 'block';
  }
}
